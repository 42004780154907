const M = {
  "AUTH-000": "Please check your password",
  "AUTH-001": "Please check your password",
  "AUTH-002": "Please check the verification code",
  "AUTH-004": "",
  "AUTH-005": "",
  "AUTH-006": "",
  "AUTH-007": "",
  "AUTH-008": "",
  "AUTH-009": "",
  "AUTH-010": "",
  "AUTH-011": "",
  "USER-001": "",
  "USER-000": "",
  "USER-003": "",
  "USER-004": "",
  "USER-005": "",
  "ADMIN-001": "",
  "ADMIN-002": "",
  "POLL-001": "",
  "POLL-002": "",
  "POLL-003": "",
  "POLL-004": "",
  "POLL-005": "",
  "POLL-006": "",
  "POLL-007": "",
  "POLL-008": "",
  "POLL-009": "",
  "POLL-010": "",
  "POLL-011": "",
  "POLL-012": "",
  "POLL-013": "",
  "POLL-014": "",
  "POLL-015": "",
  "WALLET-001": "",
  "WALLET-002": "",
  "WALLET-003": "",
  "WALLET-004": "",
  "WALLET-005": "",
  "WALLET-006": "",
  "WALLET-007": "",
  "INTERNAL-999": "",
  "NET-001": "",
  "NET-002": "",
  "NET-003": "",
  "NET-004": "",
  "DIRECT-001": "",
  "DIRECT-002": "",
  "DIRECT-003": "",
  "DIRECT-004": "",
  "INTERNAL-003": "",
  "MESSAGE-001": "",
  "MESSAGE-002": "",
  "MESSAGE-003": "",
  "MESSAGE-004": "",
  "OTP-001": "The number of verification requests has exceeded. Try again after 3 minutes",
  "OTP-002": "Please enter the correct verification code",
  "OTP-003": "The verification time has expired. Please try again.",
  "OTP-004": "Please enter the correct verification code",
  "SUBSCRIPTION-001": "",
  "SUBSCRIPTION-002": "",
  "SUBSCRIPTION-003": "",
  "SUBSCRIPTION-004": "",
  "SUBSCRIPTION-005": "",
  "SUBSCRIPTION-006": "",
  "SUBSCRIPTION-007": "",
  "SUBSCRIPTION-008": "",
  "SUBSCRIPTION-009": "",
  "SUBSCRIPTION-010": "",
  "SUBSCRIPTION-011": "",
  "PERMISSION-001": "",
  "PERMISSION-002": "",
  "PERMISSION-003": "",
  "RESULT-001": "",
  "RESULT-002": "",
  "RESULT-003": "",
  "VOTE-001": "",
  "VOTE-003": "",
  "VOTE-004": "",
  "VOTE-005": "",
  "VOTE-006": "",
  "VOTE-007": "",
  "VOTE-008": "",
  "VOTE-009": "",
  "VOTE-010": "",
  "VOTE-011": "",
  "VOTE-012": "",
  "VOTE-013": "",
  "VOTE-014": "",
  "VOTE-015": "You do not have the authority to vote.",
  "VOTE-016": "This election is canceled",
  "VOTE-017": "This election is temporally paused",
  "VOTE-018": "This election is already over",
  "VOTE-019": "Now voting is not available. Please check the time to vote available",
  "VOTER-001": "Already voted",
  "VOTER-002": "",
  "VOTER-003": "",
  "VBOOK-001": "",
  "VBOOK-002": "",
  "VBOOK-003": "",
  "VBOOK-004": "",
  "VBOOK-005": "You can no longer participate in the survey due to the limit.",
  "VBOOK-006": "You already participated in the survey.",
  "TPAY-000": "",
  "TPAY-001": "",
  "TPAY-002": "",
  "TPAY-003": "",
  "TPAY-004": "",
  "TPAY-005": "",
  "FILE-001": "",
  "FILE-002": "",
  "EVENT-000": "",
  "ORGAN-000": "",
  "PROMOTION-001": "",
  "PROMOTION-002": "",
  "PROMOTION-003": ""
}, e = {
  "AUTH-000": "비밀번호 형식을 확인해주세요",
  "AUTH-001": "비밀번호를 확인해주세요",
  "AUTH-002": "인증번호를 확인해주세요",
  "AUTH-004": "사용할 수 없는 ID입니다",
  "AUTH-005": "로그인이 만료되었어요",
  "AUTH-006": "",
  "AUTH-007": "접근 권한이 없어요",
  "AUTH-008": "접근 권한이 없어요",
  "AUTH-009": "소속을 선택해주세요",
  "AUTH-010": "계정 탈퇴를 하려면 속해있는 모든 스페이스에서 스페이스 나가기 및 삭제를 해주세요.",
  "AUTH-011": "이용 약관 위반으로 사용 중지된 계정입니다. 관리자에게 문의해주세요. 문의 1533-1912",
  "USER-001": "이미 사용중인 이메일이에요 다른 이메일을 사용해주세요",
  "USER-000": "아이디를 확인해주세요",
  "USER-003": "이미 등록된 이메일이에요 다른 이메일을 사용해주세요",
  "USER-004": "이미 등록된 전화번호에요 다른 전화번호를 사용해주세요",
  "USER-005": "등록되지 않은 이메일이에요",
  "ADMIN-001": "잘못된 요청입니다",
  "ADMIN-002": "이미 완료된 요청이에요",
  "POLL-001": "시작 시간을 현재 시간 이후로 설정해주세요",
  "POLL-002": "기간을 월 계약 기간 이내로 설정해주세요",
  "POLL-003": "개설 가능한 선거 수를 넘었어요",
  "POLL-004": "시간을 입력해주세요",
  "POLL-005": "시작시간과 종료시간을 확인해주세요",
  "POLL-006": "시작시간과 종료시간을 확인해주세요",
  "POLL-007": "이미 삭제된 항목입니다",
  "POLL-008": "존재하지 않는 항목입니다",
  "POLL-009": "이미 진행 중이기 때문에 시작 시간을 변경할 수 없어요",
  "POLL-010": "이미 완료된 요청이에요",
  "POLL-011": "",
  "POLL-012": "",
  "POLL-013": "",
  "POLL-014": "",
  "POLL-015": "",
  "WALLET-001": "포인트가 부족해요",
  "WALLET-002": "",
  "WALLET-003": "",
  "WALLET-004": "",
  "WALLET-005": "",
  "WALLET-006": "",
  "WALLET-007": "",
  "INTERNAL-999": "",
  "NET-001": "",
  "NET-002": "",
  "NET-003": "",
  "NET-004": "",
  "DIRECT-001": "",
  "DIRECT-002": "",
  "DIRECT-003": "",
  "DIRECT-004": "",
  "INTERNAL-003": "",
  "MESSAGE-001": "알림톡을 전송할 대상이 없습니다.",
  "MESSAGE-002": "존재하지 않는 항목입니다",
  "MESSAGE-003": "잘못된 요청입니다",
  "MESSAGE-004": "알림톡 전송을 위한 픽미 포인트가 부족해요. 픽미 포인트를 충전해주세요.",
  "OTP-001": "인증 요청 횟수를 초과하였습니다. 3분이 지난후에 다시 요청해주세요",
  "OTP-002": "올바른 인증번호를 입력해주세요",
  "OTP-003": "인증번호 유효시간이 지났어요. 다시 인증해주세요",
  "OTP-004": "올바른 인증번호를 입력해주세요",
  "SUBSCRIPTION-001": "",
  "SUBSCRIPTION-002": "",
  "SUBSCRIPTION-003": "",
  "SUBSCRIPTION-004": "로고를 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-005": "ULR을 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-006": "매니저를 추가하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-007": "",
  "SUBSCRIPTION-008": "",
  "SUBSCRIPTION-009": "",
  "SUBSCRIPTION-010": "유권자 수를 변경하기위해 플랜을 업그레이드 해주세요",
  "SUBSCRIPTION-011": "",
  "PERMISSION-001": "",
  "PERMISSION-002": "",
  "PERMISSION-003": "",
  "RESULT-001": "",
  "RESULT-002": "",
  "RESULT-003": "",
  "VOTE-001": "",
  "VOTE-003": "",
  "VOTE-004": "",
  "VOTE-005": "",
  "VOTE-006": "",
  "VOTE-007": "",
  "VOTE-008": "",
  "VOTE-009": "",
  "VOTE-010": "",
  "VOTE-011": "",
  "VOTE-012": "",
  "VOTE-013": "",
  "VOTE-014": "",
  "VOTE-015": "투표 할 수 있는 권한이 없어요",
  "VOTE-016": "취소된 투표에요",
  "VOTE-017": "일시 정지 중인 투표에요",
  "VOTE-018": "이미 종료된 투표에요",
  "VOTE-019": "지금은 투표 시간이 아니에요. 투표 시간을 확인해주세요",
  "VOTER-001": "이미 투표에 참여하셨어요",
  "VOTER-002": "존재하지 않는 유권자입니다",
  "VOTER-003": "이미 약관에 동의하셨어요",
  "VBOOK-001": "",
  "VBOOK-002": "",
  "VBOOK-003": "",
  "VBOOK-004": "",
  "VBOOK-005": "더이상 설문에 참여할 수 없어요",
  "VBOOK-006": "이미 참여한 설문조사이에요",
  "TPAY-000": "",
  "TPAY-001": "",
  "TPAY-002": "",
  "TPAY-003": "",
  "TPAY-004": "",
  "TPAY-005": "",
  "FILE-001": "지원하지 않는 확장자에요. 다른 파일 확장자를 사용해주세요",
  "FILE-002": "파일 용량이 초과됐어요",
  "EVENT-000": "이벤트는 한번만 참여할 수 있어요",
  "ORGAN-000": "이미 만료되었거나, 잘못된 요청이에요",
  "PROMOTION-001": "이미 참여했어요",
  "PROMOTION-002": "아직 시작 전인 프로모션이에요",
  "PROMOTION-003": "이미 종료된 프로모션이에요",
  PM_000: "",
  PM_001: "",
  PM_002: "",
  PM_003: "",
  PM_004: "",
  PM_005: "",
  PM_006: "",
  PM_007: "",
  PM_008: "",
  PM_009: "등록되지 않은 로그인 수단입니다.",
  PM_010: "",
  PM_011: "",
  PM_012: "",
  PM_013: "",
  PM_014: "",
  PM_015: "",
  PM_016: "",
  PM_017: "",
  PM_018: "해당 채널에 권한이 없습니다.",
  PM_019: "필수 값을 입력해 주세요.",
  PM_020: "필수 값을 입력해 주세요.",
  PM_021: "필수 값을 입력해 주세요.",
  PM_022: "지갑을 찾을 수 없습니다",
  PM_023: "",
  PM_024: "",
  PM_025: "",
  PM_026: "",
  PM_027: "",
  PM_028: "",
  PM_029: "",
  PM_030: "",
  PM_031: "",
  PM_032: "",
  PM_033: "",
  PM_034: "",
  PM_035: "",
  PM_036: "",
  PM_037: "",
  PM_038: "",
  PM_039: "",
  PM_040: "",
  PM_041: "",
  PM_042: "",
  PM_043: "",
  PM_044: "",
  PM_045: "",
  PM_046: "",
  PM_047: "",
  PM_048: "",
  PM_049: "",
  PM_050: "",
  PM_051: "",
  PM_052: "",
  PM_053: "",
  PM_054: "",
  PM_055: "",
  PM_056: "해당 유저의 역할을 변경할 수 없습니다.",
  PM_057: "해당 유저의 역할을 변경할 수 없습니다.",
  PM_058: "결과를 조회할 수 없습니다.",
  PM_059: "결과를 조회할 수 없습니다.",
  PM_060: "",
  PM_061: "추가 정보 수를 초과했습니다.",
  PM_062: "인증이 필요합니다.",
  PM_063: "인증이 필요합니다.",
  PM_064: "유효하지 않은 투표입니다.",
  PM_065: "",
  PM_066: "",
  PM_067: "최소 하나 이상의 투표를 등록해 주세요",
  PM_068: "최소값은 최대값 보다 작은 수를 입력해 주세요",
  PM_069: "입력한 순위를 확인해 주세요",
  PM_070: "순위 점수를 확인해 주세요",
  PM_071: "최소 점수는 최대 점수보다 작은 수를 입력해 주세요",
  PM_072: "이미 개표 완료된 투표입니다.",
  PM_073: "입력한 순위를 확인해 주세요",
  PM_074: "정수만 제출 가능 합니다.",
  PM_075: "별점을 확인해 주세요",
  PM_076: "최소값은 최대값 보다 작은 수를 입력해 주세요",
  PM_077: "답변 수를 확인해 주세요",
  PM_078: "",
  PM_079: "",
  PM_080: "",
  PM_081: "",
  PM_082: "",
  PM_083: "",
  PM_084: "",
  PM_085: "전화번호를 입력해 주세요",
  PM_086: "이메일을 입력해 주세요",
  PM_087: "존재하지 않는 질문입니다.",
  PM_088: "지원하지 않는 질문 유형입니다.",
  PM_089: "답변을 입력해 주세요",
  PM_090: "최소 점수는 최대 점수보다 작은 수를 입력해 주세요",
  PM_091: "답변을 입력해 주세요",
  PM_092: "",
  PM_093: "",
  PM_094: "",
  PM_095: "투표율을 조회할 수 없는 유형입니다.",
  PM_096: "",
  PM_097: "",
  PM_098: "실시간 결과를 볼 수 없는 유형입니다",
  PM_099: "",
  PM_100: "",
  PM_101: "",
  PM_102: "",
  PM_103: "",
  PM_104: "",
  PM_105: "코드를 확인해 주세요",
  PM_106: "참여할 수 없습니다",
  PM_107: "OTP 인증 방식이 아닌 컨텐츠입니다.",
  PM_108: "인증 방식을 확인해 주세요",
  PM_109: "시작 시간을 현재 시간 보다 이후로 설정해 주세요",
  PM_110: "",
  PM_111: "질문을 하나 이상 입력해 주세요",
  PM_112: "투표를 하나 이상 입력해 주세요",
  PM_113: "시작일은 변경할 수 없습니다.",
  PM_114: "같은 상태로 변경이 불가능합니다.",
  PM_115: "삭제할 수 없는 상태입니다.",
  PM_116: "상태를 변경할 수 없습니다.",
  PM_117: "이미 삭제되었습니다",
  PM_118: "강제 종료할 수 없는 상태입니다",
  PM_119: "",
  PM_120: "",
  PM_121: "결과 공개 여부를 변경할 수 없습니다.",
  PM_122: "투표 결과는 실시간 결과보기 옵션을 설정하거나 투표 개표 후에 게시할 수 있어요",
  PM_123: "기간을 입력해 주세요",
  PM_124: "기간은 겹치지지 않게 설정해 주세요",
  PM_125: "시작 시간은 종료 시간 보다 이전으로 설정해 주세요",
  PM_126: "",
  PM_127: "",
  PM_128: "",
  PM_129: "",
  PM_130: "",
  PM_131: "",
  PM_132: "로그인에 실패했습니다. 다시 시도해 주세요",
  PM_133: "",
  PM_134: "",
  PM_135: "계정 정보를 확인해 주세요",
  PM_136: "일시 정지된 계정입니다. 관리자에게 문의해주세요.",
  PM_137: "비밀번호가 일치하지 않습니다.",
  PM_138: "기권할 수 없는 투표입니다.",
  PM_139: "CandidateOids must be {minResponse} or more and {maxResponse} or less.",
  PM_140: "'votesPerCandidate' must be non-empty array",
  PM_141: "Abstention votes are not allowed for ProsOrConsVote",
  PM_142: "Abstention votes are not allowed for ScoreVote.",
  PM_143: "'value' must be between {minScore} and {maxScore}",
  PM_144: "Abstention votes are not allowed for RankVote",
  PM_145: "Invalid candidates object id",
  PM_146: "All candidates do not exist.",
  PM_147: "로그인 후 이용해 주세요",
  PM_148: "",
  PM_149: "",
  PM_150: "티켓에 인증 정보가 존재하지 않습니다.",
  PM_151: "명부에 등록되지 않은 인증 정보입니다.",
  PM_152: "용지를 검색할 컨텐츠 _id가 필수입니다.",
  PM_153: "Cannot vote to paused poll.",
  PM_154: "진행중인 컨텐츠가 아닙니다.",
  PM_155: "Cannot vote to poll in break time.",
  PM_156: "At least one of phone number or email is required",
  PM_157: "Name is required information.",
  PM_158: "categoryValues length should be 0 to {MAX_VOTER_BOOK_CATEGORY_LENGTH}",
  PM_159: "phoneNumber or email is required",
  PM_160: "credentialsAuthMethod가 존재하지 않습니다.",
  PM_161: "이미 사용중인 이메일입니다. 해당 아이디로 로그인 후 소셜 연동을 추가해주세요.",
  PM_162: "User already exist.",
  PM_163: "No scheduled subscription.",
  PM_164: "초대 가능한 매니저 수를 초과했습니다",
  PM_165: "This organization doesn't subscribe a pro plan",
  PM_166: "Start date is required for pro plan.",
  PM_167: "프로 플랜의 결제 유형은 월간/연간/체험만 가능합니다",
  PM_168: "Start and end date is required for custom plan",
  PM_169: "시스템 관리자를 찾을 수 없습니다.",
  PM_170: "거래 기록을 찾을 수 없습니다.",
  PM_171: "",
  PM_172: "다른 계정에서 이미 사용중인 이메일입니다.",
  PM_173: "Email is not registered.",
  PM_174: "",
  PM_175: "Duplicated email.",
  PM_176: "Should exit from all organizations",
  PM_177: "같은 제공자로 이미 등록된 인증수단이 존재합니다.",
  PM_178: "이미 해당 소셜계정과 연동된 계정이 존재합니다.",
  PM_179: "{provider}로 연동된 authMethod가 존재하지않습니다.",
  PM_180: "대표 인증 수단은 삭제할 수 없습니다.",
  PM_181: "Input string is not in MongoDB ID format.",
  PM_182: "Error creating template: {error}",
  PM_183: "Invalid api key or secret key",
  PM_184: "Channel doesn't belong to any group",
  PM_185: "boolean 문자열이 아닙니다",
  PM_186: "basic 플랜에서만 기관 삭제가 가능합니다",
  PM_187: "Privileged admin can access.",
  PM_188: "The Admin with this id is not SubAdmin Level.",
  PM_189: "대상 관리자에 대한 권한이 없습니다.",
  PM_190: "등록된 카드가 없습니다",
  PM_191: "root 인증 방식이 존재하지 않습니다.",
  PM_192: "찾을 수 없는 유저입니다",
  PM_193: "이미 이용중인 플랜이 있습니다",
  PM_194: "이미 무료 체험을 이용한 채널입니다"
}, l = "Error occurred. Please try later again.", U = "요청에 실패했습니다. 잠시 후 다시 시도해주세요.", N = (P, R) => {
  var _, T, O, t, a, E, s, n, o, i, L, S, I, d, A, c;
  return (T = (_ = P == null ? void 0 : P.response) == null ? void 0 : _.data) != null && T.code && ((t = (O = P == null ? void 0 : P.response) == null ? void 0 : O.data) != null && t.code.startsWith("DIRECT-")) ? {
    code: ((E = (a = P == null ? void 0 : P.response) == null ? void 0 : a.data) == null ? void 0 : E.code) || "XXX",
    message: (n = (s = P == null ? void 0 : P.response) == null ? void 0 : s.data) == null ? void 0 : n.message
  } : R === "en" ? {
    code: ((i = (o = P == null ? void 0 : P.response) == null ? void 0 : o.data) == null ? void 0 : i.code) || "XXX",
    message: (M == null ? void 0 : M[(S = (L = P == null ? void 0 : P.response) == null ? void 0 : L.data) == null ? void 0 : S.code]) || l
  } : {
    code: ((d = (I = P == null ? void 0 : P.response) == null ? void 0 : I.data) == null ? void 0 : d.code) || "XXX",
    message: (e == null ? void 0 : e[(c = (A = P == null ? void 0 : P.response) == null ? void 0 : A.data) == null ? void 0 : c.code]) || U
  };
}, V = (P) => {
  switch (P) {
    case "AUTH-005":
    case "WALLET-003":
      return !0;
    default:
      return !1;
  }
};
export {
  V as checkErrorExcludingFromSentryReport,
  N as parseErrorMessage
};
